import { styled } from "@mui/material/styles";
import { Stack, useTheme, useMediaQuery, Box } from "@mui/material";
import advPc from "assets/images/home/adv-pc.png";
import advM from "assets/images/home/adv-m.png";
import testnet from "assets/images/home/testnet.png";
import testnetM from "assets/images/home/testnet-m.png";

import { NavLink } from "react-router-dom";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css/navigation";

const BoxCon = styled(Box)`
  .swiper-pagination {
    bottom: 0;
  }
  .swiper-pagination-bullet {
    background: #fff;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: #4c67ef;
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      ".swiper-pagination": {
        bottom: "5px",
      },
    },
  })};
`;

const AdvertBox = styled(Stack)<{ bg: string }>`
  margin-top: 70px;
  width: 100%;
  background: ${({ bg }) => bg};
  height: 70px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      background: "none",
      height: "auto",
      marginTop: "60px",
    },
  })};
`;
const AdvertImage = styled("img")`
  width: 1200px;
  height: auto;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  })};
`;

const SwiperLink = styled(NavLink)`
  display: block;
  height: 100%;
`;

const Advert = () => {
  const theme = useTheme();
  const upToMd = useMediaQuery(theme.breakpoints.up("md"));
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const advList = [
    {
      img: testnet,
      imgM: testnetM,
      link: "https://pell-test.satoshiapp.xyz/",
      bg: "#892cdf",
    },
    {
      img: advPc,
      imgM: advM,
      link: "https://app.pell.network/gold-miner",
      bg: "#2b2c76",
    },
  ];
  return (
    <BoxCon>
      <Swiper
        onSwiper={setSwiperRef}
        initialSlide={0}
        pagination={true}
        modules={[Pagination, Autoplay]}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
      >
        {advList.map((item: any, index: number) => (
          <SwiperSlide key={index}>
            <AdvertBox
              justifyContent="center"
              alignItems="center"
              direction="row"
              bg={item.bg}
            >
              <SwiperLink target={"_blank"} to={item.link}>
                <AdvertImage src={upToMd ? item.img : item.imgM} alt="" />
              </SwiperLink>
            </AdvertBox>
          </SwiperSlide>
        ))}
      </Swiper>
    </BoxCon>
  );
};

export default Advert;
