import { styled } from "@mui/material/styles";
import { Stack, Box } from "@mui/material";

import Banner from "./components/Banner";
import Card from "./components/Card";

import { useEffect, useState } from "react";

const Containter = styled(Stack)`
  ${({ theme }) => ({
    [theme.breakpoints.down("lg")]: {},
  })};
`;

const Ecosystem = () => {
  const [swiperRef, setSwiperRef] = useState<any>(null);
  return (
    <Containter>
      <Banner></Banner>
      <Card></Card>
    </Containter>
  );
};

export default Ecosystem;
