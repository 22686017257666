import { styled } from "@mui/material/styles";
import {
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Button,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { ReactNode, useState } from "react";
import KeyIcon1 from "assets/images/v2/key-1.svg";
import KeyIcon2 from "assets/images/v2/key-2.svg";
import KeyIcon3 from "assets/images/v2/key-3.svg";
import KeyIcon4 from "assets/images/v2/key-4.svg";
import { Head, SubHead } from "./Style";
import { NavLink } from "react-router-dom";
import { useShow } from "hooks/useShow";
import Transitions from "components/Transitions";

const BanBox = styled(Stack)`
  .swiper-pagination-bullet {
    background: #fff;
  }
  .swiper-pagination-bullet-active {
    background: #4c67ef;
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {},
  })};
`;

const InfoBox = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
  margin-top: 160px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {},
  })};
`;

const InfoItemBox = styled(Stack)`
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(40px);
  width: 100%;
  padding: 100px 30px 30px;
  box-sizing: border-box;
  position: relative;
  .icon-1,
  .icon-2,
  .icon-3,
  .icon-4 {
    position: absolute;
    left: 50%;
    top: -60px;
    transform: translate(-50%, 0);
  }
  .item-title {
    color: #fff;
    text-align: center;
    font-family: Arboria-Medium;
    font-size: 20px;
  }
  .item-desc-text {
    margin: 35px 0 25px;
  }
  .icon-1 {
    width: 142px;
    height: 156px;
    background: url(${KeyIcon1}) no-repeat center;
    background-size: contain;
  }
  .icon-2 {
    width: 142px;
    height: 146px;
    background: url(${KeyIcon2}) no-repeat center;
    background-size: contain;
  }
  .icon-3 {
    width: 142px;
    height: 139px;
    background: url(${KeyIcon3}) no-repeat center;
    background-size: contain;
  }
  .icon-4 {
    width: 142px;
    height: 151px;
    background: url(${KeyIcon4}) no-repeat center;
    background-size: contain;
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {},
  })};
`;

const InfoText = styled(Typography)`
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-family: Arboria-Book;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  &.desc-text {
    width: 100%;
    max-width: 365px;
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "20px",
      "&.desc-text": {
        maxWidth: "auto",
      },
    },
  })};
`;

const Containter = styled(Stack)`
  width: 1200px;
  position: relative;
  padding: 90px 25px 109px;
  box-sizing: border-box;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "60px 20px 60px",
    },
  })};
  @media screen and (min-width: 2000px) {
    /* width: 80%; */
  }
`;

const MSwiperBox = styled("div")`
  width: 100%;
  height: 465px;
  padding-top: 60px;
  box-sizing: border-box;
`;

interface CardItem {
  icon: string;
  title: ReactNode;
  desc1: string;
  link: string;
  isDis: boolean;
  btnText: string;
}

const KeyComponents = () => {
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const theme = useTheme();
  const downToMd = useMediaQuery(theme.breakpoints.down("md"));
  const { ref, isShow } = useShow();
  const cardData: CardItem[] = [
    {
      title: `Pell Restaking Protocol`,
      desc1:
        "A suite of smart contracts deployable across multiple networks enables stakers to restake BTC LSD.",
      icon: "icon-1",
      link: "https://app.pell.network",
      btnText: "Explore More",
      isDis: false,
    },
    {
      title: (
        <>
          Pell ‘s <br /> Marketplace
        </>
      ),
      desc1:
        "Any service provider can leverage BTC LSD security by offering rewards to Pell AVS operators",
      icon: "icon-2",
      link: "",
      isDis: true,
      btnText: "Coming Soon",
    },
    {
      title: `Pell Restaking Ledger`,
      desc1:
        "A specially designed, decentralized ledger for managing the Pell Restaking Protocol",
      icon: "icon-3",
      link: "https://docs.pell.network/",
      isDis: false,
      btnText: "Learn More",
    },
    {
      title: `The Pell Network Client`,
      desc1:
        "A binary run locally by operators, effectively creating a p2p validation network",
      icon: "icon-4",
      link: "",
      isDis: true,
      btnText: "Coming Soon",
    },
  ];
  return (
    <BanBox justifyContent="center" alignItems="center" direction="row">
      <Containter>
        <Stack justifyContent="center" alignItems="center" direction="row">
          <Transitions delay={200} isShow={isShow}>
            <Head variant="h1">Key Components</Head>
          </Transitions>
        </Stack>
        <Stack
          ref={ref}
          mt={20}
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <Transitions delay={400} isShow={isShow}>
            <SubHead variant="subtitle1">
              Pell extends the cryptoeconomic security of BTC and its LSD to
              additional applications through multiple coordinated components.
            </SubHead>
          </Transitions>
        </Stack>
        <Transitions isUp={false} delay={600} isShow={isShow}>
          {!downToMd ? (
            <InfoBox>
              {cardData.map((item: CardItem, index: number) => {
                return (
                  <InfoItemBox
                    justifyContent={"center"}
                    alignItems="center"
                    direction={"column"}
                  >
                    <Box flex={1}>
                      <div className={item.icon}></div>
                      <div className="item-title">{item.title}</div>
                      <InfoText className="item-desc-text">
                        {item.desc1}
                      </InfoText>
                    </Box>
                    <Stack
                      mt={30}
                      justifyContent="center"
                      alignItems="center"
                      direction="row"
                    >
                      <NavLink
                        to={item.link}
                        target={item.isDis ? "_self" : "_blank"}
                      >
                        <Button disabled={item.isDis} variant={"contained"}>
                          {item?.btnText}
                        </Button>
                      </NavLink>
                    </Stack>
                  </InfoItemBox>
                );
              })}
            </InfoBox>
          ) : (
            <Box mt={40}>
              <Swiper
                onSwiper={setSwiperRef}
                initialSlide={0}
                spaceBetween={20}
                pagination={true}
                modules={[Pagination]}
                style={{ width: "100%" }}
              >
                {cardData.map((item: CardItem, index: number) => (
                  <SwiperSlide style={{ height: "525px" }} key={index}>
                    <MSwiperBox>
                      <InfoItemBox
                        justifyContent={"center"}
                        alignItems="center"
                        direction={"column"}
                        style={{ height: "435px" }}
                      >
                        <Box flex={1}>
                          <div className={item.icon}></div>
                          <div className="item-title">{item.title}</div>
                          <InfoText className="item-desc-text">
                            {item.desc1}
                          </InfoText>
                        </Box>
                        <Stack
                          mt={30}
                          justifyContent="center"
                          alignItems="center"
                          direction="row"
                        >
                          <NavLink
                            to={item.link}
                            target={item.isDis ? "_self" : "_blank"}
                          >
                            <Button disabled={item.isDis} variant={"contained"}>
                              {item?.btnText}
                            </Button>
                          </NavLink>
                        </Stack>
                      </InfoItemBox>
                    </MSwiperBox>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          )}
        </Transitions>
      </Containter>
    </BanBox>
  );
};

export default KeyComponents;
